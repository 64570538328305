import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Container } from '@mui/material';
import {  styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import axios from 'axios';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import StoreIcon from '@mui/icons-material/Store';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Stack from '@mui/material/Stack';

const ManagedStatUffici = () => {
  const [campaignMenu, setCampaignMenu] = useState([]);
  const [campaignSelected, setCampaignSelected] = useState('');
  const [campaignData, setCampaignData] = useState([]);
  const [campaignOveralData, setCampaignOveralData] = useState({});
  const [loading, setLoading] = useState(false);

  const { REACT_APP_API_ENDPOINT } = process.env;

  const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

useEffect(() => {
    const fetchCampaignProgress = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns-affissioni-menu`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign:', response.data);
        setCampaignMenu(response.data);
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
      }
    };
    
    // Usa l'ID della campagna che vuoi testare
    fetchCampaignProgress(); // Assicurati che questo ID esista nel database
  }, []);


  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  function chartSetting(labels, media){

      return  {
    yAxis: [
      {
        label: `${labels}`,
      },
    ],
    width: 250,
    height: 300,
    sx: {[`.${axisClasses.left} .${axisClasses.label}`]: {transform: 'translate(-8px, 10)',},
    },
  };
  }
  const colors = [
    '#00cc00',
    '#FF8F33',
    '#ff33ff',
    '#72ccff'];

/*
/api/depositi-managed/stat/${campaignId}/overall-progress
*/

const fetchCampaignOveralProgress = async (campaignId) => {
      try {
        setLoading(true)
       // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns/${campaignId}/overall-progress`, {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/statistiche/uffici/overal/${campaignId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign Progress:', response.data);
        setCampaignOveralData(response.data);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
      }
}


/*
api/depositi-managed?campaignId=${campaignId}
*/

const fetchCampaignProgress = async (campaignId) => {
      try {
       // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns/${campaignId}/progress`, {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/stat/uffici/managed?campaignId=${campaignId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign Progress:', response.data);
        setCampaignData(response.data);
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
      }
}

const handleChange = (event) => {
  console.log(campaignMenu);
  setCampaignSelected(event.target.value);
  fetchCampaignOveralProgress(event.target.value)
  fetchCampaignProgress (event.target.value)
  }; 

const MenuCampagne = () => {

  return (
    <>
    <Box component="section" sx={{ p: 2}}>
  <FormControl fullWidth>
  <InputLabel id="demo-simple-label">Seleziona Campagna</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    label="Seleziona Campagna"
    id="demo-simple-select"
    value={campaignSelected}
    onChange={handleChange}
  >
  {campaignMenu?.map((option, index) => {
          return (
            <MenuItem key={option._id} value={option._id}>
              {option.denominazione}
            </MenuItem>
          )
})}

  </Select>
</FormControl>
</Box>
</>
  )
}
//  [totalCar, completedCars, inProgressCars, completionRate] = campaignOveralData;

  return (
    <Container >
    <Box sx={{ padding: '0 !important' }}>
    <Stack alignItems="center" direction="row" gap={2} sx={{ mb:4 }}>
      <StoreIcon />
      <Typography variant="body1"> Dashboard progresso campagna Uffici managed</Typography>
    </Stack>
     
    {MenuCampagne()}

    {campaignData.length > 0 ?
      <>
    <Typography variant="subtitle1" color="primary" gutterBottom sx={{ mt:4 }}>
       Percentuale completata: {campaignOveralData.completionRate} % -  Totali: {campaignOveralData.totalUffici} - Completati {campaignOveralData.completedUffici} - MQ: {campaignOveralData.mq}
      </Typography>
      <BarChart style={{marginLeft: '23px'}}
      series={[
        { data: [campaignOveralData.totalUffici], label: 'Uffici' },
        { data: [campaignOveralData.completedUffici], label: 'Completati' },
        { data: [campaignOveralData.mq], label: 'MQ' },
      ]}
      barLabel="value"
      // yAxis={[{ scaleType: 'band', data: [['Mezzi'], ['Complet.'], ['Depositi' ],['Complet.'],] }]}
        colors={[ '#FF8F33','#0000ff',  '#00cc00']}
        layout="horizontal"
        grid={{ vertical: true }}
        width={850}
        height={250}
      />
</>
      : null
    }
    </Box>
    <div style={{ width: '100%' }}  >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

      {campaignData.map((progress, index) => (

        <Grid  key={index} size={{ xs: 12, md: 3 }}>
          <Item>

          <BarChart style={{marginLeft: '23px'}}
          //  dataset={progress}
          xAxis={[{ scaleType: 'band', data: ['completed', 'uffici', 'MQ',], colorMap: {
            type: 'ordinal',
            colors,
          },}]}
          //  xAxis={[{ scaleType: 'band', dataKey: "garage" }]}
          series={[{ data: [progress.numero_uffici, progress.numero_uffici, progress.MQ], label: `${100}% completato` }]}
          slotProps={{ legend: { hidden: false } }}
          barLabel="value"
          /*
            series={[
              { dataKey: progress.completed, label: 'completed', fill: '#82ca9d' },
              { dataKey: progress.in_progress, label: 'in progress', fill: '#8884d8' },
              { dataKey: progress.total, label: 'tot. mezzi', fill: ' #DAF7A6' }
            ]}
*/
           {...chartSetting(progress.denominazione, progress.media)}
          />
                
          </Item>
        </Grid>

              ))}


      </Grid>
    </div>
</Container >
  );
};

export default ManagedStatUffici;
