import { useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_EditActionButtons as mr_EditActionButtons,
} from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {  Box, DialogTitle, Button, DialogContent, DialogActions} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
//import "./style_table2.css";

import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  keepPreviousData,
  useQuery,
  useQueryClient,

} from '@tanstack/react-query';
//Your API response shape will probably be different. Knowing a total row count is important though.

const Example = () => {
  //manage our own state for stuff we want to pass to the API
  const [validationErrors, setValidationErrors] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { REACT_APP_API_ENDPOINT } = process.env;

  //consider storing this code in a custom hook (i.e useFetchUsers)
  const {
    data: { data = [], meta } = {}, //your data and api response will probably be different
    isError: isLoadingUsersError,
    isRefetching,
    isLoading: isLoadingUsers,
   // refetch,
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(`${REACT_APP_API_ENDPOINT}/api/depositi/garages`);

      //read our state and pass it to the API as query params
      fetchURL.searchParams.set(
        'start',
        `${pagination.pageIndex * pagination.pageSize}`,
      );
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
      fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

      //use whatever fetch library you want, fetch, axios, etc
      const response = await fetch(fetchURL.href);
      const json = (await response.json());
      return json;
    },
    placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
  });

  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorKey: '_id',
        header: '_id',
        filterVariant: 'text', // default
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'regione',
        header: 'Regione',
        filterVariant: 'text', // default
        size: 200,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.regione,
          helperText: validationErrors?.regione,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              regione: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'recapito',
        header: 'Recapito',
        filterVariant: 'text', // default
        size: 200,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.recapito,
          helperText: validationErrors?.recapito,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              recapito: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'localita',
        header: 'Localita',
        filterVariant: 'text', // default
        size: 200,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.localita,
          helperText: validationErrors?.localita,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              localita: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'indirizzo',
        header: 'Indirizzo',
        filterVariant: 'text', // default
        size: 300,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.indirizzo,
          helperText: validationErrors?.indirizzo,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              indirizzo: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'CAP',
        header: 'CAP',
        filterVariant: 'text', // default
        size: 200,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.CAP,
          helperText: validationErrors?.CAP,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              CAP: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'provincia',
        header: 'PROV.',
        filterVariant: 'text', // default
        size: 200,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.provincia,
          helperText: validationErrors?.provincia,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              provincia: undefined,
            }),

          //optionally add validation checking for onBlur or onChange
        },
      },
    ],
    [validationErrors],
    //end
  );

   //call CREATE hook
  const { mutateAsync: createUser, isPending: isCreatingUser } = useCreateUser();
  const { mutateAsync: updateUser, isPending: isUpdatingUser } = useUpdateUser();
  //call DELETE hook
  const { mutateAsync: deleteUser, isPending: isDeletingUser } = useDeleteUser();


  //CREATE action

  const handleCreateUser = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});

    await createUser(values);

    table.setCreatingRow(null); //exit creating mode

  };

//READ hook (get users from api)

  //UPDATE action

  const handleSaveUser = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});
    await updateUser(values);
    table.setEditingRow(null); //exit editing mode
  };


  //DELETE action

  const openDeleteConfirmModal = (row) => {

    if (window.confirm('Are you sure you want to delete this user?')) {

      deleteUser(row.original.id);

    }

  };

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true },
    tableLayout: 'auto',
    manualFiltering: false, //turn off built-in client-side filtering
    manualPagination: false, //turn off built-in client-side pagination
    manualSorting: false, //turn off built-in client-side sorting
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    /*
    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
*/
    rowCount: meta?.totalRowCount ?? 0,
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    //optionally customize modal content

    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (

      <>
        <DialogTitle variant="h6">Aggiungi un Garage</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <mr_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),

    //optionally customize modal content

    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (

      <>
        <DialogTitle variant="h3">Edit User</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <mr_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),

    renderRowActions: ({ row, table }) => (

      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>

    ),

    renderTopToolbarCustomActions: ({ table }) => (

      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Create New Garage
      </Button>
    ),
    state: {
      columnFilters,
      globalFilter,
      isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
      isLoading: isLoadingUsers,
      pagination,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  //CREATE hook (post new user to api)


  return <MaterialReactTable className="css-1k4gpqb"
  table={table}
  muiTableContainerProps={{maxWidth: '100%' }}
  />;
};

function useCreateUser() {

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(
        ['users'],
        (prevUsers) =>
          [
            ...prevUsers,
            {
              ...newUserInfo,
              id: (Math.random() + 1).toString(36).substring(7),
            },
          ],
      );

    },

    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo

  });

}

//READ hook (get users from api)

function useGetUsers() {

  return useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      //send api request here
     // await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
    //  return Promise.resolve(fakeData);

    },
    refetchOnWindowFocus: false,
  });
}

//UPDATE hook (put user in api)

function useUpdateUser() {

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },

    //client side optimistic update

    onMutate: (newUserInfo) => {
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.map((prevUser) =>
          prevUser.id === newUserInfo.id ? newUserInfo : prevUser,
        ),

      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });

}

//DELETE hook (delete user in api)

function useDeleteUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (userId) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve()
    },

    //client side optimistic update

    onMutate: (userId) => {
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId),

      );

    },

    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo

  });

}




const queryClient = new QueryClient();

const Garages = () => (
  //App.tsx or AppProviders file. Don't just wrap this component with QueryClientProvider! Wrap your whole App!
  <QueryClientProvider client={queryClient}>
    <Example />
  </QueryClientProvider>
);

export default Garages;

const validateRequired = (value) => !!value.length;

function validateUser(user) {

  return {

    reagione: !validateRequired(user.regione)  ? 'Regione is Required' : '',

    recapito_CDC:  !validateRequired(user.recapito_CDC)  ? 'recapito_CDC is Required' : '',

    localita: !validateRequired(user.localita) ? 'Località is Required' : '',
    
    indirizzo: !validateRequired(user.indirizzo) ? 'Indirizzo is Required' : '',

    CAP: !validateRequired(user.cap) ? 'CAP is Required' : '',

    provincia: !validateRequired(user.provincia) ? 'Provincia is Required' : '',

    
  };

}