import React, { useState,useEffect, useContext } from 'react';
import { Container, AppBar, Toolbar, Typography, IconButton, Button, Box, MenuList } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Logos from '../Risors1@2x.png';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import BusinessIcon from '@mui/icons-material/Business';
import ImageIcon from '@mui/icons-material/Image';
import LogoSinergica from '../SINERGICA-20-2-300x88.png';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import BarChartIcon from '@mui/icons-material/BarChart';
import PlaceIcon from '@mui/icons-material/Place';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

//import { useTheme } from '@mui/material/styles';
//import { Brightness4, Brightness7 } from '@mui/icons-material';
import { ThemeContext } from '../context/ThemeContext'; // Assicurati che il percorso sia corretto
import { Brightness4 as DarkModeIcon, Brightness7 as LightModeIcon } from '@mui/icons-material'; // Importa le icone
import { styled } from '@mui/material/styles';


import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';

import MenuItem from '@mui/material/MenuItem';

import WebStoriesIcon from '@mui/icons-material/WebStories';
import UserMenu from './Menu/UserMenu';
import BasicMenu from './Menu/BasicMenu';
import UserMenuAttivita from './Menu/UserMenuAttivita';
import GestioneMenu from './Menu/GestioneMenu';

import AccountMenu from './Menu/AccountMenu';
import MapGoogle from '../mapGoogle/MapGoogle';




const Home = ({ setIsAuthenticated, props }) => {

  const [isAdmin, setIsAdmin] = useState(false);
  const [adminName, setAdminName] = useState('')
  const [isUser, setIsUser] = useState(false);
  const [userName, setUsername] = useState('')
  const {darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
// const theme = useTheme();
  const navigate = useNavigate();
  


  const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log(decodedToken)
        if (decodedToken.role === 'admin') {
          setIsAdmin(true); // Imposta isAdmin a true se il ruolo è admin
          setAdminName(decodedToken.username);
        }
        if (decodedToken.role === 'user') {
          setIsUser(true); // Imposta isAdmin a true se il ruolo è admin
          setUsername(decodedToken.username);
        }
      } catch (error) {
        console.error('Errore nella decodifica del token:', error);
      }
    }
  }, []);

  const [anchorNav, setAnchorNav] = React.useState(null);

  const closeMenu = ()=>{
    setAnchorNav(null);
  }
//  const [rotta, setRotta] =  React.useState([]);

 
/*
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
*/

const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


const handleMenuItemClick = (event, index) => {
    //  setSelectedIndex(index);
    if (index === 1) {
          navigate(`/home/admin/dash-depositi`)
      }
      if (index === 2) {
          navigate('/home/admin/panel-depositi');
      }
      if (index === 3) {
        navigate('/home/admin/dash-uffici');
      }
      if (index === 4) {
      navigate('/home/admin/panel-affissioni');
      }
      if (index === 5) {
          navigate(`/home/uffici-all`)
      }
      if (index === 6) {
          navigate('/home/garage/');
      }
      if (index === 7) {
        navigate('/home/flotta');
      }
      if (index === 8) {
      navigate('/home/admin/registation');
      }
      if (index === 9) {
        navigate('/home/campain');
      }
      if (index === 10) {
      navigate('/home/campain-affissioni');
      }
      if (index === 11) {
        navigate('/home/automobile/');
      }
      if (index === 12) {
      navigate('/home/uffici');
      }
      if (index === 13) {
      navigate('/home/images');
      }
 
    
      toggleDrawer(false)
    };


  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
      <MenuList
        autoFocusItem={open}
        id="composition-menu"
        aria-labelledby="composition-button"
      //  onKeyDown={}
      >
      </MenuList>

      <ListItem  disablePadding disabled>
        <ListItemButton disabled>
          <ListItemIcon>
          Admin Menu
          </ListItemIcon>
          <ListItemText />
        </ListItemButton>
      </ListItem>

      <MenuList>
 
        <MenuItem onClick={(event) => handleMenuItemClick(event, 1)}>
          <ListItemIcon>
            <BarChartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Dash Depositi</ListItemText>
   
        </MenuItem>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 2)}>
          <ListItemIcon>
            <BusinessIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Panel Depositi</ListItemText>

        </MenuItem>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 3)}>
          <ListItemIcon>
            <BarChartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Dash Affissioni</ListItemText>

        </MenuItem>
 
        <MenuItem onClick={(event) => handleMenuItemClick(event, 4)}>
          <ListItemIcon>
            <AirportShuttleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Panel Affissioni</ListItemText>

        </MenuItem>

        <Divider />

      </MenuList>


      <ListItem  disablePadding>
        <ListItemButton disabled>
          <ListItemIcon>
          Gestione Menu
          </ListItemIcon>
          <ListItemText />
        </ListItemButton>
      </ListItem>

      <MenuList>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 5)}>
          <ListItemIcon>
            <BarChartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Uffici</ListItemText>
   
        </MenuItem>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 6)}>
          <ListItemIcon>
            <BusinessIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Garage</ListItemText>

        </MenuItem>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 7)}>
          <ListItemIcon>
            <BarChartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Flotta</ListItemText>

        </MenuItem>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 8)}>
          <ListItemIcon>
            <AirportShuttleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Utenti</ListItemText>

        </MenuItem>

        <Divider />

      </MenuList>
    

{/* user menu */}


<ListItem  disablePadding>
        <ListItemButton disabled>
          <ListItemIcon>
          Campagne
          </ListItemIcon>
          <ListItemText />
        </ListItemButton>
      </ListItem>

      <MenuList>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 9)}>
          <ListItemIcon>
            <BarChartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Depositi</ListItemText>
   
        </MenuItem>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 10)}>
          <ListItemIcon>
            <BusinessIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Affissioni</ListItemText>

        </MenuItem>

        <Divider />

      </MenuList>
    
{/* Attivita */}

<ListItem  disablePadding>
        <ListItemButton  disabled>
          <ListItemIcon>
          Attività
          </ListItemIcon>
          <ListItemText />
        </ListItemButton>
      </ListItem>

      <MenuList>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 11)}>
          <ListItemIcon>
            <BarChartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Valida Mezzi</ListItemText>
   
        </MenuItem>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 12)}>
          <ListItemIcon>
            <BusinessIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Valida uffici</ListItemText>

        </MenuItem>

        <Divider />

      </MenuList>

      <ListItem  disablePadding>
        <ListItemButton disabled>
          <ListItemIcon>
          Foto lavoro
          </ListItemIcon>
          <ListItemText />
        </ListItemButton>
      </ListItem>

      <MenuList>
        <MenuItem onClick={(event) => handleMenuItemClick(event, 13)}>
          <ListItemIcon>
            <ImageIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Foto</ListItemText>
        </MenuItem>
        <Divider />
      </MenuList>

      <ListItem  disablePadding>
        <ListItemButton disabled>
          <ListItemIcon>
          Tools
          </ListItemIcon>
          <ListItemText />
        </ListItemButton>
      </ListItem>

      <ListItem  disablePadding>
        <ListItemButton>
          <ListItemIcon onClick={toggleDarkMode}>
          {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
          </ListItemIcon >
          {darkMode ? <ListItemText  primary={'Light'} onClick={toggleDarkMode} /> : <ListItemText  primary={'Dark'} onClick={toggleDarkMode} /> }
        </ListItemButton>
      </ListItem>

      <ListItem  disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={'Logout'} onClick={()=>handleLogout()} />
            </ListItemButton>
          </ListItem>

      {isAdmin && (
        <>

      </>
      )}

      {/*isUser && (

      )*/}



    </List>

    </Box>
  );


  const handleLogout = () => {
    // Rimuovi il token dal localStorage
    localStorage.removeItem('token');

    // Reindirizza l'utente alla pagina di login
    navigate('/login');
  };


  return (
<>
<div>
    <AppBar position='static'>
    <Toolbar>

    <Typography variant='h6' component ='div' sx={{flexGrow:1, display:{xs: 'none', md:'flex'}}}><img src={Logos} alt="Logo" style={{width: '14%'}}/></Typography>
    <Box sx={{display:{xs: 'none', md:'flex'}}}>

    <Box >
    <IconButton size='large' color='inherit' edge='start' onClick={() => navigate('/home/mappa')}>
    <PlaceIcon />
    </IconButton>
    </Box>

   <Button onClick={toggleDrawer(true)}>Open drawer</Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
         DrawerList 
      </Drawer>

      {isAdmin && (
        <>
        <BasicMenu />
        <GestioneMenu />
        
        </>
      )}
      {isUser && (
        <>
          <UserMenu />
          <UserMenuAttivita />
          <Button color="inherit" onClick={() => navigate('/home/images')}>Foto</Button>
          </>
        )}

      <IconButton onClick={toggleDarkMode} color="inherit">
      {/* Mostra il sole o la luna a seconda della modalità */}
      {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
    </IconButton>
    <Box sx={{ flexGrow: 0 }}>

    <AccountMenu />
    

  </Box>


  {/* inizio side menu */}

    </Box >

    
    <Box sx={{display:{xs: 'flex', md:'none'}}}>
    <IconButton size='large' color='inherit' edge='start' onClick={toggleDrawer(true)}>
    <MenuIcon />
    </IconButton>

    <Drawer open={open} onClose={toggleDrawer(false)}>
      {DrawerList}
    </Drawer>
    
  {/* 
    <Menu open={anchorNav} onClick={closeMenu} sx={{display:{sx: 'flex', md:'none', top: '0px !important'}}}>
    <MenuList>

    {isAdmin && (
      <MenuItem onClick={() => navigate('/home/admin/dash')}>
        Dash
      </MenuItem>
    )}
    
    {isAdmin && (
      <MenuItem onClick={() => navigate('/home/admin')}>
       Panel
      </MenuItem>
    )}

    {isUser && (
      <UserMenu />
    )}
  
    {isUser && (
      <>
      <MenuItem onClick={() => navigate('/home/campain')}>Submit</MenuItem>
      <MenuItem onClick={() => navigate('/home/upload')}>Upload</MenuItem>
      <MenuItem onClick={() => navigate('/home/images')}>Foto</MenuItem>
      </>
    )}
      <MenuItem onClick={() => navigate('/home/automobile')}>Mezzi</MenuItem>
      <MenuItem onClick={() => navigate('/home/images')}>Foto</MenuItem>
      <Box sx={{ flexGrow: 0 }}>          
      <IconButton onClick={toggleDarkMode} color="inherit">
     
      {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
    </IconButton>  
      </Box>

    </MenuList>
    </Menu>
    */}

    </Box>

    <Typography component ='div' sx={{flexGrow:1, display:{sx: 'flex', md:'none'}}}><img src={Logos} alt="Logo" style={{width: '26%'}} sx={{flexGrow:1, display:{sx: 'flex', md:'none'}}}/></Typography>

    </Toolbar>
    </AppBar>

    <Box>
        {adminName && (

          <Grid container spacing={2}>
            <Grid size={12}>
              {darkMode ?  
                <Item sx={{ color: 'yellow' }}>Benvenuto: {adminName}</Item>
               :           
                <Item sx={{ color: 'black' }}>Benvenuto: {adminName}</Item>
              }

            </Grid>
          </Grid>
        )}

    {userName && (
      <Grid container spacing={2}>
        <Grid size={12}>
        {darkMode ?
          <Item sx={{color:'yellow'}}>Benvenuto: {userName}</Item>
        :
        <Item sx={{color:'black'}}>Benvenuto: {userName}</Item>
        }
        </Grid>
    </Grid>

    )}


  

    <Outlet />
    {/*    <Container fixed sx={{ mt: 4 }}>
       Questo renderizza i figli in base alla rotta
        <Outlet />
      </Container> */}



    </Box>
    </div>

      <Container maxWidth="false" style={{ position: 'fixed', bottom: 2, left: 2, right: 2 }}>
        {/*  <Typography variant="body1">
            My sticky footer can be found here.
          </Typography> */}
        <img src={LogoSinergica} alt="Logo" style={{ width: '10%' }} />
        <Typography variant="body2" color="textSecondary">
          {"Copyright © "}
          <Link color="inherit" href="https://sinergicasrl.com/">
            Sinergica
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>
 
    </>
  );
};

export default Home;
