import React, { useEffect, useState } from 'react';
import createAuthInterceptor from '../utils/authInterceptor'; // Correggi il percorso se necessario
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Card, CardMedia, CardContent, CardHeader } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { green } from '@mui/material/colors';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { enqueueSnackbar } from 'notistack';

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const { REACT_APP_API_ENDPOINT } = process.env;

function UserImages() {
  const navigate = useNavigate();
  const axiosInstance = createAuthInterceptor(navigate); // Crei l'istanza qui
  const [images, setImages] = useState([]);

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axiosInstance.get('api/images/user-images', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setImages(response.data);
      } catch (error) {
        console.error('Errore nel recupero delle immagini:', error);
        enqueueSnackbar('Errore nel recupero delle immagini', { variant: 'error' });
      }
    };

    fetchImages(); // Chiamata alla funzione
  }, []);

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Le Tue Immagini
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {images.map((image, index) => (
            <Card key={image._id} sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
                  <LocationOnIcon />
                  </Avatar>
                }
                title={image.ufficioPt === "" ? image.depositoPt.recapito: image.ufficioPt.denominazione} 
                subheader={image.username}
              />
              <CardMedia
                component="img"
                height="140"
                image={`${REACT_APP_API_ENDPOINT}/api/${image.path}`}
                alt="Immagine"
                onClick={()=>handleClick(index)}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                 {image.location}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                 {image.address}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                 {new Date(image.uploadDate).toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
          ))}
          {!!currentImage && (
            /* @ts-ignore */
            <Lightbox
              mainSrc={`${REACT_APP_API_ENDPOINT}/api/${currentImage.path}`}
              imageTitle={currentImage.address}
              mainSrcThumbnail={currentImage.path}
              nextSrc={`${REACT_APP_API_ENDPOINT}/api/${nextImage.path}`}
              nextSrcThumbnail={`${REACT_APP_API_ENDPOINT}/api/${nextImage.path}`}
              prevSrc={`${REACT_APP_API_ENDPOINT}/api/${prevImage.path}`}
              prevSrcThumbnail={`${REACT_APP_API_ENDPOINT}/api/${prevImage.path}`}
              onCloseRequest={handleClose}
              onMovePrevRequest={handleMovePrev}
              onMoveNextRequest={handleMoveNext}
            />
          )}
    
        </Box>
      </Box>
    </Container>
  );
}

export default UserImages;
