import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth'; // Assicurati che questo sia corretto
import Login from './pages/Login';
import Home from './pages/Home';
import Upload from './pages/Upload';
import Gallery from './pages/Gallery';
import UserImages from './pages/UserImages'

import { CircularProgress } from '@mui/material';
import AdminPanelGarages from './pages/AdminPanelGarages';
import CampaignDepositiSelection from './Campaign/CampaignDepositiSelection';
import CarSearchAndUpdate from './pages/CarSearchAndUpdate';
import CampaignDashboard from './pages/CampaignDashboard';
import Garages from './pages/Garages';
import ParcoAuto from './pages/ParcoAuto';
import CampaignAffissioniSelection from './Campaign/CampaignAffissioniSelection';
import UfficiSearchAndUpdate from './pages/UfficiSearchAndUpdate';
import CampaignAffissioniDashboard from './pages/CampaingnAffissioniDash';
import AdminPanelAffissioni from './pages/AdminPanelAffissioni';
import RegistrationForm from './pages/RegistrationForm';
import Uffici from './pages/Uffici';
import AllImages from './pages/AllImages';
import ManagedPanelDepositi from './pages/Managed/Depositi/ManagedPanelDepositi';
import ManagedStatDepositi from './pages/Managed/Stat/ManagedStatDepositi';
import ManagedPanelAffissioni from './pages/Managed/Affissioni/ManagedPanelAffissioni';
import ManagedStatUffici from './pages/Managed/Stat/ManagedStatUffici';
import ManagedUsers from './UsersManaged/ManagedUsers';
import MapGoogle from './mapGoogle/MapGoogle';
import MappX from './mapGoogle/Mapp';


const App = ({ toggleTheme, mode }) => {
  const { isAuthenticated, loading } = useAuth(); // Questo deve recuperare il contesto
  console.log(useAuth());
  if (loading) {
    return <CircularProgress />; // Mostra un indicatore di caricamento finché non carica
  }

  return (
    <>
      <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/home"
        element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
      >
      <Route path="mappa" element={<MappX />} />
      <Route path="campain" element={<CampaignDepositiSelection />} />
      <Route path="campain-affissioni" element={<CampaignAffissioniSelection />} />
        <Route path="upload" element={<Upload />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="images" element={<UserImages />} />
        <Route path='automobile' element={<CarSearchAndUpdate />} />
        <Route path='uffici' element={<UfficiSearchAndUpdate />} />
        <Route path='uffici-all' element={<Uffici />} />
        <Route path='garage' element={<Garages />} />
        <Route path='flotta' element={<ParcoAuto />} />
        <Route path='manage/depositi' element={<ManagedPanelDepositi />} />
        <Route path='manage/uffici' element={<ManagedPanelAffissioni />} />
        <Route path='manage/users' element={<ManagedUsers />} />

        <Route path="admin/panel-depositi" element={<AdminPanelGarages />} />
        <Route path="admin/dash-dep" element={<ManagedStatDepositi />} />
        <Route path="admin/dash-uff" element={<ManagedStatUffici />} />
       
        <Route path="admin/panel-affissioni" element={<AdminPanelAffissioni />} />
        <Route path="admin/dash-depositi" element={<CampaignDashboard />} />
        <Route path="admin/dash-uffici" element={<CampaignAffissioniDashboard />} />
        <Route path="admin/registation" element={<RegistrationForm />} />
        <Route path="admin/all-images" element={<AllImages />} />
        
       {/* <Route path="admin/tutte" element={<AdminTutte />} />
        <Route path="admin/imagesdetail" element={<AdminImagesDetails />} /> */}
      </Route>
      <Route path="*" element={<Navigate to={isAuthenticated ? "/home" : "/login"} />} />
    </Routes>
    </>
  );
};

export default App;
