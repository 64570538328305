import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, CircularProgress } from '@mui/material';
import { responsiveFontSizes, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import axios from 'axios';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Stack from '@mui/material/Stack';

const ManagedStatDepositi = () => {
  const [campaignMenu, setCampaignMenu] = useState([]);
  const [campaignSelected, setCampaignSelected] = useState('');
  const [campaignData, setCampaignData] = useState([]);
  const [campaignOveralData, setCampaignOveralData] = useState({});
  const [loading, setLoading] = useState(false);

  const { REACT_APP_API_ENDPOINT } = process.env;

  const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

useEffect(() => {
    const fetchCampaignProgress = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns-menu`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign:', response.data);
        setCampaignMenu(response.data);
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
      }
    };
    
    // Usa l'ID della campagna che vuoi testare
    fetchCampaignProgress('66f852d3bf7b41110a6d0323'); // Assicurati che questo ID esista nel database
  }, []);


  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  function chartSetting(labels, media){

      return  {
    yAxis: [
      {
        label: `${labels}`,
      },
    ],
    width: 250,
    height: 300,
    sx: {[`.${axisClasses.left} .${axisClasses.label}`]: {transform: 'translate(-8px, 10)',},
    },
  };
  }
  const colors = [
  '#02b2af',
  '#FF8F33',
  '#72ccff',
  '#B833FF',
  '#33FFF5',
  '#da00ff',
  '#FF33A1',

];

const chartOverallSetting = {
  xAxis: [
    {
      label: 'range (mm)',
    },
  ],
  width: 900,
  height: 400,
  sx: {[`.${axisClasses.left} .${axisClasses.label}`]: {transform: 'translate(-8px, 28)',},
    },
    
};


const overalDataset = [
  {
    
    seoul: 600,
    month: 'mezzi',
    label: 'Series A1'
  },
  {
   
    seoul: 131,
    month: 'compl.',
    label: 'Series A2'
  },
  {
   
    seoul: 45,
    month: 'dep.',
    label: 'Series A3'
  },
  {
   
    seoul: 5,
    month: 'comp.',
    label: 'Series A4'
  },
]
/*
/api/depositi-managed/stat/${campaignId}/overall-progress
*/

const fetchCampaignOveralProgress = async (campaignId) => {
      try {
       // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns/${campaignId}/overall-progress`, {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/depositi-managed/stat/${campaignId}/overall-progress`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign Progress:', response.data);
        setCampaignOveralData(response.data);
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
      }
}


/*
api/depositi-managed?campaignId=${campaignId}
*/

const fetchCampaignProgress = async (campaignId) => {
      try {
       // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns/${campaignId}/progress`, {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/depositi-managed?campaignId=${campaignId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign Progress:', response.data);
        setCampaignData(response.data);
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
      }
}

const handleChange = (event) => {
  console.log(campaignMenu);
  setCampaignSelected(event.target.value);
  fetchCampaignOveralProgress(event.target.value)
  fetchCampaignProgress (event.target.value)
  }; 

const MenuCampagne = () => {

  return (
    <>
  <FormControl fullWidth>
  <InputLabel id="demo-label-campagna">Seleziona Campagna</InputLabel>
  <Select

  labelId="demo-simple-select-label"
  label="Seleziona Campagna"
  id="demo-simple-select"
    value={campaignSelected}
    onChange={handleChange}
  >
  {campaignMenu?.map((option, index) => {
          return (
            <MenuItem key={option._id} value={option._id}>
              {option.denominazione}
            </MenuItem>
          )
})}

  </Select>
</FormControl>
</>
  )
}
//  [totalCar, completedCars, inProgressCars, completionRate] = campaignOveralData;

  return (
<>
<Container >
<Box sx={{ padding: '0 !important' }}>
<Stack alignItems="center" direction="row" gap={2} sx={{ mb:4 }}>
  <LocalShippingIcon />
  <Typography variant="body1"> Dashboard progresso campagna depositi managed</Typography>
</Stack>
 
{MenuCampagne()}

{campaignData.length >0 ?

 <div>
<Typography variant="subtitle1" color="primary" gutterBottom sx={{ mt:2 }} >
  Depositi: {campaignOveralData.completionRateDepositi}% completati, (Depositi: {campaignOveralData.totalDepositi}, Completati {campaignOveralData.completedDepositiManaged}),
  Mezzi: {campaignOveralData.completionRateCars}% completati, (Mezzi: {campaignOveralData.totalCars} - Completati {campaignOveralData.completedCarsManaged})
  </Typography>

  <BarChart
  series={[
    { data: [campaignOveralData.totalCars], label: 'Mezzi' },
    { data: [campaignOveralData.completedCarsManaged], label: 'Complet.' },
    { data: [campaignOveralData.totalDepositi], label: 'Depositi' },
    { data: [campaignOveralData.completedDepositiManaged], label: 'Complet.' },
  ]}
  barLabel="value"
// yAxis={[{ scaleType: 'band', data: [['Mezzi'], ['Complet.'], ['Depositi' ],['Complet.'],] }]}

layout="horizontal"
grid={{ vertical: true }}
width={800}
  height={250}
/>
</div>
  : null

}
</Box>
<div>
  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

  {campaignData.map((progress, index) => (

    <Grid  key={index} size={{ xs: 12, md: 3 }}>
      <Item>

      <BarChart style={{marginLeft: '23px'}}
      //  dataset={progress}
      xAxis={[{ scaleType: 'band', data: ['completed', 'n° auto'], colorMap: {
        type: 'ordinal',
        colors,
      },}]}
      //  xAxis={[{ scaleType: 'band', dataKey: "garage" }]}
      series={[{ data: [progress.numero_auto, progress.numero_auto], label: `${100}% completato` }]}
      slotProps={{ legend: { hidden: false } }}
      barLabel="value"
      /*
        series={[
          { dataKey: progress.completed, label: 'completed', fill: '#82ca9d' },
          { dataKey: progress.in_progress, label: 'in progress', fill: '#8884d8' },
          { dataKey: progress.total, label: 'tot. mezzi', fill: ' #DAF7A6' }
        ]}
*/
       {...chartSetting(progress.garageInfo.recapito, progress.media)}
      />
            
      </Item>
    </Grid>

          ))}


  </Grid>
</div>
</Container>
</>





  );
};

export default ManagedStatDepositi;
