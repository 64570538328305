import { useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
    MRT_TableContainer,
    MRT_EditActionButtons,
  } from 'material-react-table';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import RefreshIcon from '@mui/icons-material/Refresh';
import Container from '@mui/material/Container';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
  } from '@mui/material';
  import { TextField, Checkbox, FormControlLabel, Typography, MenuItem, Select, InputLabel, FormControl } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
//import "./style_table.css";


import {
    keepPreviousData,
    useQuery,
  } from '@tanstack/react-query'; //note: this is TanStack React Query V5
import { getUsers } from './Api/ApiUsers'

const useStyles = makeStyles((theme) => ({
    root: {
      "&MuiContainer-root": {
        maxWidth: false,
    }
    },
  }));

const AppUsers = () => {

    const classes = useStyles();
  //manage our own state for stuff we want to pass to the API
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [validationErrors, setValidationErrors] = useState({});

  //  const saluti = "hello"

    const  {
        data: { data = [], meta } = {},
        error: userError,
        isLoading: isUsersLoading,
        isRefetching,
        refetch,
    } =    
    useQuery({
      queryKey: ['getUsers', {columnFilters, globalFilter, pagination, sorting}],
      queryFn: getUsers,
      placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
    });

    /*
    if (userError && !isUsersLoading) {
        return <div>An error occurs while fetching Users</div>
    }
*/
    const columns = useMemo(
        () => [
            {
              accessorKey: '_id',
              header: '_id',
            },
            {
                accessorKey: 'username',
                header: 'Username',
              },
            {
              accessorKey: 'nome',
              header: 'Nome',
            },
            {
              accessorKey: 'cognome',
              header: 'Cognome',
            },
            {
                accessorKey: 'email',
                header: 'Email',
                muiEditTextFieldProps: {
                    type: 'email',
                    required: true,
                    error: !!validationErrors?.email,
                    helperText: validationErrors?.email,
                    //remove any previous validation errors when user focuses on the input
                    onFocus: () =>
                      setValidationErrors({
                        ...validationErrors,
                        email: undefined,
                      }),
                  },
              },
              {
                accessorKey: 'password',
                header: 'PW',
              },
              {
                accessorKey: 'role',
                header: 'Ruolo',
              },
        ],
        [validationErrors],
      );


 // console.log(data)

   //DELETE action
   const openDeleteConfirmModal = (row) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
    //  deleteUser(row.original.id);
    }
  };
    
 const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true },
    manualFiltering: false, //turn off built-in client-side filtering
    manualPagination: false, //turn off built-in client-side pagination
    manualSorting: false, //turn off built-in client-side sorting
    muiToolbarAlertBannerProps: userError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
    rowCount: meta?.totalRowCount ?? 0,
   /*  onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser, */
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Create New User</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Modifica Utente</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '0rem' }}
        >
        {/* {
    "_id": "66e01994937f9222fb3032df",
    "nome": "Sandro",
    "cognome": "Peggion",
    "email": "sandro.peggion@gmail.com",
    "password": "$2a$10$gM7kD1200y/5.5VZOcNJ.eSHpOdlKlWoVXBRn8EzEjRineq1WPzZm",
    "role": "admin",
    "createdAt: ": "2024-10-18T08:07:59.867Z",
    "username": "sundsx"
}*/}
          {/*internalEditComponents*/} {/* or render custom edit components here */}

          <TextField
          label="Nome"
          name="nome"
       //   value={formData.nome}
       //   onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Cognome"
          name="cognome"
       //   value={formData.cognome}
       //   onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email"
          name="email"
        //  value={formData.email}
        //  onChange={handleChange}
          fullWidth
          margin="normal"
          required
          type="email"
        />
        <TextField
          label="Username"
          name="username"
      //    value={formData.username}
      //    onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Password"
          name="password"
       //   value={formData.password}
       //   onChange={handleChange}
          fullWidth
          margin="normal"
          required
          type="password"
        />
                {/* Nuovo campo select per il ruolo */}
                <FormControl fullWidth margin="normal" required>
                <InputLabel id="role-label">Ruolo</InputLabel>
                <Select
                labelId="role"
                label={"Role"}
                  id="role"
                  name="role"
              //    value={formData.role}
              //    onChange={handleChange}
                >
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="manager">Manager</MenuItem>
                </Select>
              </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              name="active"
           //   checked={formData.active}
            //  onChange={handleChange}
              color="primary"
            />
          }
          label="Attivo"
        />





        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    state: {
      columnFilters,
      globalFilter,
      isUsersLoading,
      pagination,
      showAlertBanner: userError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return <MaterialReactTable className="css-1k4gpqb"
  table={table} />;
    
    
 }
 export default AppUsers;


 const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

function validateUser(users) {
  return {
    firstName: !validateRequired(users.firstName)
      ? 'First Name is Required'
      : '',
  //  lastName: !validateRequired(users.lastName) ? 'Last Name is Required' : '',
    email: !validateEmail(users.email) ? 'Incorrect Email Format' : '',
  };
}