import React, { useState, useEffect } from 'react';
import ImageUploadAffissioni from './ImageUploadAffissioni'; // Importa il componente ImageUpload
import { getCampaignsAffissioni, getAffissioniRegions, getAffissioniCities, getUfficiAffisione, getUfficioAffisioneId } from './apiAffissioni'; // Assicurati che il percorso sia corretto
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';

const CampaignAffissioniSelection = () => {
const [campaigns, setCampaigns] = useState([]);
  const [affissioniRegions, setAffissioniRegions] = useState([]);
  const [cities, setCities] = useState([]);
 

  const [uffici, setUffici] = useState([]);
  const [selectedNote, setSelectedNote] = useState('');
  const [selectedAnno, setSelectedAnno] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedUfficio, setSelectedUfficio] = useState('');
  const [selectedUfficioId, setSelectedUfficioId] = useState({})

 // Carica le campagne all'avvio
 useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const data = await getCampaignsAffissioni();
        setCampaigns(data);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };

    fetchCampaigns();
  }, []);

  // Carica le regioni all'avvio
  useEffect(() => {
    const fetchRegions = async () => {
        try {
            const response = await getAffissioniRegions();
            console.log(response)
            setAffissioniRegions(response);
        } catch (error) {
            console.error('Error fetching affissioniRegions:', error);
        }
     
      
    };

    fetchRegions();
  }, []);

  // Popola le città in base alla regione selezionata
  useEffect(() => {
    const fetchCities = async () => {
      if (selectedRegion) {
        const response = await getAffissioniCities(selectedRegion);
        setCities(response);
      }
    };

    fetchCities();
  }, [selectedRegion]);

  // Popola gli uffici in base alla città selezionata
  useEffect(() => {
    const fetchUffici = async () => {
      if (selectedCity) {
        const response = await getUfficiAffisione(selectedCity);
        setUffici(response);
      }
    };

    fetchUffici();
  }, [selectedCity]);


  // recupera l' _id dell'uficio per perjointare i mq
useEffect(() => {
  const fetchUfficioId = async () => {
      if (selectedUfficio) {
        const response = await getUfficioAffisioneId(selectedUfficio);
        setSelectedUfficioId(response);
      }
    };

    fetchUfficioId();
}, [selectedUfficio])


  return (
    <div>
    
      <h1>Seleziona Campagna per Affissioni</h1>


      <Grid container spacing={1} style={{marginBottom: '25px'}}>

      <Grid size={4}>
      <Grid item  xs={12} sm={5} md={4}>
      <FormControl  fullWidth >
        <InputLabel id="select-anno-label">Seleziona Anno</InputLabel>
        <Select
        style={{padding:'4px 4px'}}
        value={selectedAnno}
        onChange={(e) => setSelectedAnno(e.target.value)}
        labelId="anno-select-label"
        label={"Seleziona Anno"}
        >
        <MenuItem key={'2024'} value={'2024'}>
              {'2024'}
            </MenuItem>
        </Select>
      </FormControl>
      </Grid>
    </Grid>

      <Grid size={4}>
          <Grid item  xs={12} sm={5} md={4}>
          <FormControl  fullWidth >
            <InputLabel id="campagna">Seleziona Campagna</InputLabel>
            <Select
            labelId="campagna-label"
              label={"Seleziona Campagna"}
            style={{padding:'4px 4px'}}
            value={selectedCampaign}
            onChange={(e) => setSelectedCampaign(e.target.value)}
            >
              {campaigns.map((campaign) => (
                <MenuItem key={campaign._id} value={campaign._id}>
                  {campaign.denominazione}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
        </Grid>

        <Grid size={4}>
        <Grid item  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="regione">Seleziona Regione</InputLabel>
              <Select
              labelId="regione-label"
              label={"Seleziona Regione"}
              style={{padding:'4px 4px'}}
                value={selectedRegion}
                onChange={(e) => setSelectedRegion(e.target.value)}
              >
                {affissioniRegions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

        </Grid>

        <Grid size={4}>
        <Grid item  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="citta">Seleziona Città</InputLabel>
              <Select
              labelId="citta-label"
              label={"Seleziona Città"}
              style={{padding:'4px 4px'}}
                value={selectedCity === -1 ? '' : selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                disabled={!selectedRegion} // Disabilita il selettore città finché la regione non è selezionata
              >
                {cities.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>




              {/* 
        
      <select onChange={(e) => setSelectedUfficio(e.target.value)}>
        <option value="">Seleziona Ufficio</option>
        {uffici.map((ufficio) => (
          <option key={ufficio} value={ufficio}>{ufficio}</option>
        ))}
      </select>
        */}


        <Grid size={8}>
        <Grid item  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="ufficio-postale">Ufficio PT</InputLabel>
              <Select
                style={{padding:'4px 4px'}}
                labelId="ufficio-postale-label"
                label={"Seleziona Ufficio postale"}

                id="ufficio-simple-select"
                value={selectedUfficio === -1 ? '' : selectedUfficio}
                onChange={(e) => setSelectedUfficio(e.target.value)}
                disabled={!selectedCity} // Disabilita il selettore garage finché la città non è selezionata
              >
                {uffici.map((ufficio) => (
                  <MenuItem key={ufficio} value={ufficio}>
                    {ufficio}
                  </MenuItem>
                ))}
              </Select>
            </FormControl >
          </Grid>
        </Grid>

        <Grid size={12}>
        <Grid item  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <TextField 
              id="outlined-basic-note" 
              label="Note (facoltativo)" 
              variant="outlined" 
              value={selectedNote === -1 ? '' : selectedNote}
              onChange={(e) => setSelectedNote(e.target.value)}
              disabled={!selectedCity}
              />
            </FormControl >
          </Grid>
        </Grid>

        
        <h1><p />{selectedUfficio}</h1>

      {/* Mostra l'uploader delle immagini dopo che tutte le selezioni sono state fatte */}
      {selectedAnno && selectedCampaign && selectedRegion && selectedCity && selectedUfficio && selectedUfficioId && (
        <ImageUploadAffissioni 
          anno={selectedAnno}
          campaign={selectedCampaign} 
          region={selectedRegion} 
          city={selectedCity} 
          ufficio={selectedUfficio} 
          ufficioId={selectedUfficioId}
          note={selectedNote}
        />
      )}


      </Grid>
     </div>
  );
};

export default CampaignAffissioniSelection;
