
const { REACT_APP_API_ENDPOINT } = process.env;

export const getUsers = async ({ queryKey }) => {

    const [_key, {columnFilters, globalFilter, pagination, sorting}] = queryKey

    const resp =  new URL(`${REACT_APP_API_ENDPOINT}/api/users/getall`);
       //read our state and pass it to the API as query params
        resp.searchParams.set(
            'start',
            `${pagination.pageIndex * pagination.pageSize}`,
          );
         
          resp.searchParams.set('size', `${pagination.pageSize}`);
          resp.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
          resp.searchParams.set('globalFilter', globalFilter ?? '');
          resp.searchParams.set('sorting', JSON.stringify(sorting ?? []));  
    
          //use whatever fetch library you want, fetch, axios, etc
          const response = await fetch(resp.href);
          const json = (await response.json());
          return json;
}
