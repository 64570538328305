
import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Button, FormControl, InputLabel } from '@mui/material';
import {  Typography, Card, CardMedia, Container, CardHeader, CardContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Grid from '@mui/material/Grid2';

/*
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
*/
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const { REACT_APP_API_ENDPOINT } = process.env;

//import { Navigate, useNavigate } from 'react-router-dom';

const AdminPanelGarages = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [regioni, setRegioni] = useState([]);
  const [citta, setCitta] = useState([]);
  const [garage, setGarage] = useState([]);
  const [selectedAnno, setSelectedAnno] = useState('');
  const [selectedCampaigns, setSelectedCampaigns] = useState('');
  const [selectedRegione, setSelectedRegione] = useState('');
  const [selectedCitta, setSelectedCitta] = useState('');
  const [selectedGarage, setSelectedGarage] = useState('');
  const [images, setImages] = useState([]);
  //const [open, setOpen] = useState(false);
  //const navigate = useNavigate();
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [loading, setLoading] = useState(false);

// ligthbox
const [index, setIndex] = useState(-1);
const currentImage = images[index];
const nextIndex = (index + 1) % images.length;
const nextImage = images[nextIndex] || currentImage;
const prevIndex = (index + images.length - 1) % images.length;
const prevImage = images[prevIndex] || currentImage;

const handleClick = (index, item) => setIndex(index);
const handleClose = () => setIndex(-1);
const handleMovePrev = () => setIndex(prevIndex);
const handleMoveNext = () => setIndex(nextIndex);

// Per recuperare le campagne disponibili dal backend
  useEffect(() => {
    const fetchRegioni = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns`);
        setCampaigns(response.data);
      } catch (error) {
        console.error('Errore nel recupero delle regioni:', error);
      }
    };

    fetchRegioni();
  }, []);

  // Per recuperare le regioni disponibili dal backend
  useEffect(() => {
    const fetchRegioni = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/regioni`);
        setRegioni(response.data);
      } catch (error) {
        console.error('Errore nel recupero delle regioni:', error);
      }
    };

    fetchRegioni();
  }, []);

  // Quando una regione è selezionata, recupera le città corrispondenti
  useEffect(() => {
    if (selectedRegione) {
      const fetchCitta = async () => {
        try {
          const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/citta?regione=${selectedRegione}`);
          setCitta(response.data);
        } catch (error) {
          console.error('Errore nel recupero delle città:', error);
        }
      };

      fetchCitta();
    }
  }, [selectedRegione]);

  // Quando una città è selezionata, recupera i garage corrispondenti
  useEffect(() => {
    if (selectedCitta) {
      const fetchGarage = async () => {
        try {
          const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/indirizzo?citta=${selectedCitta}`);
          setGarage(response.data);
        } catch (error) {
          console.error('Errore nel recupero dei garage:', error);
        }
      };

      fetchGarage();
    }
  }, [selectedCitta]);
 
  const handleFilter = () => {
    // Invio di una richiesta con i parametri di filtro
    axios
      .get(`${REACT_APP_API_ENDPOINT}/api/admin/campaign-images`, {
        params: {
          campaignId: selectedCampaigns,
          regione: selectedRegione,
          citta: selectedCitta,
          garage: selectedGarage,
          anno:selectedAnno,
        },
      })
      .then((response) => {
        console.log('Immagini filtrate:', response.data);
        if(response.data.length > 0) {
        setNoRecordFound(false);
      } else {
        setNoRecordFound(true);
      }
        // Gestione delle immagini filtrate
        setImages(response.data);
      })
      .catch((error) => {
        console.error('Errore nel recupero delle immagini:', error);
      });
  };

  const handleReset = () => {
   
    setSelectedRegione('');
    setSelectedCitta('');
    setSelectedGarage('');
    console.log("resetto")
    setLoading(false);
}

  return (
    <Container >
    <div style={{ marginBottom: '25px' }}>
      <h2>Admin Panel - Filtra per Campagna Auto</h2>
      </div>
      <Stack direction="row" spacing={1} sx={{mb: 2}}>
      {loading ? 
        <CircularProgress color="inherit" />
        : <IconButton aria-label="delete" onClick={()=> { setLoading(true); handleReset();} }>
        <AutorenewIcon />
      </IconButton> }
      
      <Box sx={{ '& > button': { m: 1 } }}>
      {loading ? 
        <LoadingButton
          size="small"
          
          loading={loading}
          variant="outlined"
          disabled
        >
          Disabled
        </LoadingButton>
        : null }
        </Box>
    </Stack>
      <Grid container spacing={1} style={{marginBottom: '25px'}}>

      <Grid size={4}>
      <Grid item  xs={12} sm={5} md={4}>
      <FormControl  fullWidth >
        <InputLabel id="select-anno-label">Seleziona Anno</InputLabel>
        <Select
        style={{padding:'4px 4px'}}
        value={selectedAnno}
        onChange={(e) => setSelectedAnno(e.target.value)}
        labelId="anno-select-label"
        label={"Seleziona Anno"}
        >
        <MenuItem key={'2024'} value={'2024'}>
              {'2024'}
            </MenuItem>
        </Select>
      </FormControl>
      </Grid>
    </Grid>

        <Grid size={4}>
          <Grid item  xs={12} sm={5} md={4}>
          <FormControl  fullWidth >
            <InputLabel id="campagna-label">Seleziona Campagna</InputLabel>
            <Select
            style={{padding:'4px 4px'}}
            value={selectedCampaigns}
            onChange={(e) => setSelectedCampaigns(e.target.value)}
            labelId="campagna-select-label"
            label={"Seleziona campagna"}
            >
              {campaigns.map((campain) => (
                <MenuItem key={campain._id} value={campain._id}>
                  {campain.denominazione}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
        </Grid>

        <Grid size={4}>
        <Grid item  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="regione-label">Seleziona Regione</InputLabel>
              <Select
              style={{padding:'4px 4px'}}
                value={selectedRegione}
                onChange={(e) => setSelectedRegione(e.target.value)}
                labelId="regione-select-label"
                label={"Seleziona Regione"}
              >
                {regioni.map((regione) => (
                  <MenuItem key={regione} value={regione}>
                    {regione}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

        </Grid>

        <Grid size={4}>
        <Grid item  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="citta-label">Seleziona Città</InputLabel>
              <Select
              style={{padding:'4px 4px'}}
                value={selectedCitta}
                onChange={(e) => setSelectedCitta(e.target.value)}
                disabled={!selectedRegione} // Disabilita il selettore città finché la regione non è selezionata
                labelId="citta-select-label"
                label={"Seleziona città"}
                >
                {citta.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid size={8}>
        <Grid item  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="garage-label">Deposito</InputLabel>
              <Select
               style={{padding:'4px 4px'}}
               labelId="garage-select-label"
               label={"Garage"}
                value={selectedGarage}
                onChange={(e) => setSelectedGarage(e.target.value)}
                disabled={!selectedCitta} // Disabilita il selettore garage finché la città non è selezionata
              >
                {garage.map((g) => (
                  <MenuItem key={g} value={g}>
                    {g}
                  </MenuItem>
                ))}
              </Select>
            </FormControl >
          </Grid>
        </Grid>
        <Grid size={12}>
      <Button
              variant="contained"
              
              onClick={handleFilter}
            >
              Filtra Immagini
            </Button>
        </Grid>
      </Grid>

      {noRecordFound ?  
        <h2>
        Nessun record trovato
        </h2> 
        : null
      }
  
      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      {images.map((image, index) => (
          <Grid key={image._id} size={{ xs: 2, sm: 4, md: 4 }}>
            
            <Card key={image._id} sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  <LocationOnIcon />
                  </Avatar>
                }
                
                title={image.location}
                subheader={new Date(image.uploadDate).toLocaleString()}
              />
              <CardMedia
                component="img"
                height="140"
                image={`${REACT_APP_API_ENDPOINT}/api/${image.path}`}
                alt="Immagine"
                onClick={()=>handleClick(index)}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                {image.address}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                   Utente: {image.username}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Data: {new Date(image.uploadDate).toLocaleString()}
                </Typography>
              </CardContent>
            </Card>

           
          </Grid>

        ))}
      </Grid>

      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={`${REACT_APP_API_ENDPOINT}/api/${currentImage.path}`}
          imageTitle={`${currentImage.address} - ${new Date(currentImage.uploadDate).toLocaleDateString()}`}
          mainSrcThumbnail={currentImage.path}
          nextSrc={`${REACT_APP_API_ENDPOINT}/api/${nextImage.path}`}
          nextSrcThumbnail={`${REACT_APP_API_ENDPOINT}/${nextImage.path}`}
          prevSrc={`${REACT_APP_API_ENDPOINT}/api/${prevImage.path}`}
          prevSrcThumbnail={`${REACT_APP_API_ENDPOINT}/api/${prevImage.path}`}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </Container >
  );
};

export default AdminPanelGarages;
